.featuresWrapper{
    padding: 48px 0px;
}
.featuresWrapper h1{
    color: var(--black);
    text-align: center;
}

.featureContent{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.featureBox{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}