.mobileAppWrapper{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.mobileAppContent{
    border: 1px solid var(--black);
    width: 100%;
    margin-top: 32px;
    padding: 16px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--light-green);
}

.mobileAppButton{
    background-color: black !important;
    color: white !important;
    width: 150px;
    border-radius: 100px !important;
    padding: 12px !important;
}