.widthDrawWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 69px);
    width: 100%;
    background-color: var(--primary);
}

.withDrawContainer {
    padding: 16px;
    background: var(--green);
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    width: 500px;
    height: auto;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
}


.withdrawTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
    color: var(--white);
}

.mobile {
    margin-bottom: 64px;
}
.withdrawInput{
    height: 58px !important;
    color: var(--white) !important;
}
.formContainer {
    width: 100% !important;
}

.formWrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
}

.myWalletContainer p {
    font-size: 16px;
    font-weight: 600;
}

.toWalletContainer p,
.withdrawAmountContainer p {
    font-size: 14px;
    font-weight: 600;
}

.toWalletContainer,
.withdrawAmountContainer {
    margin-top: 12px;
}

.withdrawCheckboxContainer {
    margin-top: 8px;
}

.withdrawSendContainer {
    margin-top: 12px;
    position: relative;
    z-index: 999;
}
.maxbutton{
    border: 1px solid var(--white) !important;  
    margin-left: 8px !important;
    border-radius: 100px !important;
    color: var(--white) !important;
}
.withdrawSubmitButton {
    width: 100% !important;
    border-radius: 100px !important;
    background-color: var(--black) !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    color: var(--white) !important;
}

@media (max-width:1300px) {
    .widthDrawWrapper {
        height: auto;
    }

    .withdrawSendContainer {
        margin-top: 8px;
    }
}

.successMessageContainer {
    width: 100%;
    background: rgba(0, 128, 0, .5);
    height: 55px;
    border-radius: 8px;
}

.successMessageContainer p {
    padding: 8px;
    color: #228B22;
    font-size: 18px;
    font-weight: 600;
}

.errorMessageContainer {
    width: 100%;
    background: rgba(255, 122, 89, .5);
    height: 55px;
    border-radius: 8px;
}

.errorMessageContainer p {
    padding: 8px;
    color: red;
    font-size: 18px;
    font-weight: 600;
}