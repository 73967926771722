.blogWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/images/download.svg);
    padding: 32px 0px;
}

.blogTitle {
    width: 100%;
}
.blogTitle img {
    animation: moveUpDown 10s linear infinite;
  }
  
  @keyframes moveUpDown {
    0%, 100% {
      transform: translateY(50px);
    }
    50% {
      transform: translateY(-50px); /* Adjust the pixel value as needed */
    }
  }
.blogTitle p {
    color: var(--black);
    font-size: 2.5vw;
    font-weight: bold;
    letter-spacing: -0.02rem;
    text-align: center;
}

.scrollBlogContainer {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    overflow-x: scroll;
}

.scrollBlogContainer::-webkit-scrollbar {
    display: none;
}

.blogContainer {
    margin-bottom: 16px;
    background-color: var(--secondary);
    border-radius: 24px;
    width: 100% !important;
    display: flex;
    align-items: center;
    gap: 16px;
}

.blogContainer p {
    margin: 0;
    padding: 16px 16px 0px 16px;
}

.blogContainer h3 {
    margin: 0;
    padding: 16px;
    font-weight: 500;
    color: var(--white);
}

.footerNewsContainer {
    display: flex;
    justify-content: center;
    background-color: var(--green);
    margin: 0px 16px;
    padding: 8px;
    border-radius: 100px;

}


.blogContainer a {
    padding-left: 16px;
    text-decoration: none;
    color: var(--white);
    font-weight: bold;
    text-align: center;
}
