.profileWrapper {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    background: var(--green);
    margin-top: 64px;
    width: 500px;
    height: 450px;
    aspect-ratio: 1/1;
    border-radius: 32px !important;
    display: flex;
    flex-direction: column;
}

.profileTitleContainer {
    margin: 0px;
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
    text-align: center;
}

.profileTitle {
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
}

.emailInputContainer {
    display: flex;
}
.emailInputContainer div{
    flex: 1;
}

.nameInputContainer div p,
.emailInputContainer div p,
.usernamePassInputContainer div p,
.walletInputContainer p {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
}

.walletInputContainer {
    margin-left: 16px;
}

.emailInputContainer {
    margin-top: 0px;
}

.nameInputContainer {
    display: flex;
}
.nameInputContainer div{
    flex: 1;
}
.usernamePassInputContainer {
    display: flex;
}
.profileinputDisabled{
    height: 40px;
    font-size: 18px;
    padding-left: 8px;
    color: var(--white) !important;
    border-radius: 100px;
    border: 1px solid var(--white);
}