
    :root {
        --primary: #D0D9CF;
        --green : #7fd1ae;
        --secondary: #004eb6;
        --secondary2: #ddf2ff;
        --black: #1C1F2A;
        --white: #F1F7FC;
        --light-green:#F7FCFB;
    }
    

@font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/Roboto-Regular.ttf);
}

body {
    margin: 0px;
    font-family: roboto;
    /* background-color: var(--primary) !important; */
}

body::-webkit-scrollbar {
    display: none;
}