.cryptoCurrencyWrapper{
    padding: 32px 0px;
    background-color: var(--light-green);
}
.cryptoCurrencyContent{
    width: 100%;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.cryptoCurrencyContentData{
    width: 450px !important;
    height: 180px;
    background-color: white;
    border-radius: 32px;
    padding: 16px;
    border: 1px solid var(--black);
}
.cryptoCurrencyContentData h4{
    opacity: 0.5;
    font-weight: bold;
}