h2 {
    color: var(--primary);
}

.emailTo {
    color: var(--white);
    font-weight: 700;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0 !important;
}

.css-twia2z-MuiPaper-root-MuiDialog-paper {
    border-radius: 32px !important;
}

.css-ohyacs {
    padding: 0 !important;
}

.ContactUsSubmitButton {
    margin-top: 12px !important;
    width: 100% !important;
    border-radius: 100px !important;
    background-color: transparent !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    background-color: var(--black) !important;
}

.contactUsModalWrapper{
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    padding: 16px;
    overflow: hidden;
    position: relative;
    border-radius: 16px;
    display: flex;
    align-items : center;
    flex-direction: column;
    background-color: var(--green);
}

.ContactUsTitleContainer {
    margin: 0px;
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    text-align: center;
}
fieldset{
    border-radius: 32px !important;
}
.ContactUsTitleContainer h2 {
    color: var(--white);
    margin: 0;
}
