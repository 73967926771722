.SignupWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
}

.SignupWrapper img {
    animation: moveUpDown 8s linear infinite;
  }
  
  @keyframes moveUpDown {
    0% ,100%{
      transform: translateY(70px);
    }
    50% {
      transform: translateY(-70px); 
    }
  }

.SignupContainer {
    position: relative;
    background: var(--green);
    width: 60%;
    min-height: 70vh;
    border-radius: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.SignupContainerChildOne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.SignupContainerChildOne h1{
    font-size: 3.5vw;
    color: var(--white);
}
.SignupArrowBack {
    position: absolute;
    top: 40px;
    margin-left: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.SignupContainer .SignupContainerChildOne h1 {
    margin-bottom: 16px;
}

form {
    width: 90% !important;
}

@media (max-width:700px) {
    .SignupContainer {
        width: 85%;
    }

    form {
        width: 90% !important;
    }
}

@media (max-width:500px) {
    .SignupContainer {
        width: 95%;
    }

    form {
        width: 90% !important;
    }
}

h1 {
    margin-bottom: 32px;
    color: var(--secondary)
}

.signupsubmitButton {
    padding: 16px !important;
    width: 100%;
    border-radius: 100px !important;
    background-color: var(--black) !important;
}

.date {
    background-color: transparent !important;
    height: 58px !important;
    width: 100%;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none !important;
    border-radius: 100px !important;
    border: 1px solid var(--white) !important;
    color: var(--white);
    cursor: pointer;
    padding-left: 16px;
}
.phoneContainer div div div::before{
    border-bottom: none !important;
    color: var(--white) !important;
}
.phoneContainer div div div{
    color: var(--white) !important;
}
.phoneNumber{
    border: 1px solid var(--white) !important;
    height: 58px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    color: var(--white) !important;
}

@media (max-width:1250px) {
    .SignupArrowBack {
        top: 25px;
    }
}
