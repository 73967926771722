.transactionsWrapper {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    background:var(--green);
    min-width: 700px !important;
    height: 500px;
    aspect-ratio: 1/1;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
}

.transactionstTitleContainer {
    margin: 0px;
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
    text-align: center;
}

.transactionsContainer {
    padding: 16px;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    overflow-y: auto;
    position: relative;
    z-index: 9999;
}

.transactionsContainer::-webkit-scrollbar {
    display: none;
}

.transactionsTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
}

.transactionsInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}