.bodyWrapper {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    background-image: url(../../assets/images/background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}

.bodyWrapper h1{
    margin-top: 0;
    font-size: 4vw !important;
    font-weight: bolder;
    color: var(--black);
    padding-left: 16px;
}

.bodyButton{
    margin-left: 16px !important;
    width: 300px !important;
    padding: 16px !important;
    border-radius: 100px !important;
    background-color: var(--green) !important;
}