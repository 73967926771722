.depositeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 69px);
    width: 100%;
    background-color: var(--primary);
}

.depositeContainer {
    padding: 16px;
    background: var(--green);
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    width: 500px;
    height: auto;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
}

.depositeTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
    color: var(--white);
}

.depositeAmountContainer {
    margin-top: 32px;
}

.depositeCheckboxContainer {
    margin-top: 32px;
}

.depositeSendContainer {
    margin-top: 32px;
}
.formContainer {
position: relative;
z-index: 999;
}
.inputDisabled {
    height: 58px;
    width: 96.5%;
    font-size: 18px;
    padding-left: 8px;
    color: var(--white) !important;
    border-radius: 100px;
    border: 1px solid var(--white);
    /* background-color: var(--grren); */
}

.copyButton {
    margin-top: 16px !important;
    width: 100% !important;
    border-radius: 100px !important;
    background-color: transparent !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    background-color: var(--black) !important;
}